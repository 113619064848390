<template>
  <div
    v-if="show"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__right"
    id="newTaskModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="modal__dialog" role="document">
      <transition name="slide" appear>
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a
                class="modal__close modal__close--black"
                @click="$emit('close')"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital text-break">
              Create New Task
            </h5>
          </div>
          <div class="modal__body">
            <form>
              <div class="form__item">
                <label class="form__label">Summary</label>
                <input
                  type="text"
                  class="input form__input form__input--lg"
                  placeholder="Write your task summary"
                  v-model="task.taskTitle"
                  v-bind:class="{
                    'input-required': taskFormErr.taskTitle,
                  }"
                />
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label for="" class="form__label">Linked To</label>
                    <select
                      class="select select--lg"
                      required
                      v-model="taskType"
                      @change="changeSearchParams"
                    >
                      <option value="__Select Field Set__" disabled selected>
                        __Select Field Set__
                      </option>
                      <option
                        v-for="(taskType, index) in allTaskType"
                        :key="index"
                        :value="taskType.value"
                      >
                        {{ taskType.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <div
                    class="form__item"
                    v-if="this.taskType.toLowerCase() === 'customer'"
                  >
                    <label class="form__label"
                      >Select {{ taskType }} name</label
                    >
                    <multiselect
                      v-model="selectedCustomer"
                      id="linked"
                      label="customerFirstName"
                      :custom-label="customCustomer"
                      track-by="customerFirstName"
                      placeholder="Type to search"
                      :options="this.customers"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="3"
                      :limit-text="limitText"
                      :max-height="800"
                      :show-no-results="false"
                      :hide-selected="true"
                      @input="changeSelectedKey"
                      @change="changeSelectedKey"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.customerName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                  <div
                    class="form__item"
                    v-else-if="this.taskType.toLowerCase() === 'group'"
                  >
                    <label class="form__label">Select {{ taskType }}</label>
                    <multiselect
                      v-model="selectedCustomer"
                      id="linked"
                      label="groupName"
                      track-by="groupName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="this.groups"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="3"
                      :limit-text="limitText"
                      :max-height="800"
                      :show-no-results="false"
                      :hide-selected="true"
                      @input="changeSelectedKey"
                      @change="changeSelectedKey"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.groupName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                  <div
                    class="form__item"
                    v-else-if="
                      this.taskType.toLowerCase() === 'deposit account'
                    "
                  >
                    <label class="form__label"
                      >Select {{ taskType }} name</label
                    >
                    <multiselect
                      v-model="selectedCustomer"
                      id="linked"
                      label="savingsAccountName"
                      track-by="savingsAccountName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="sortedDeposits"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="7"
                      :max-height="800"
                      :loading="loading"
                      :preselect-first="false"
                      @input="changeSelectedKey"
                      @change="changeSelectedKey"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.groupName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                  <div
                    class="form__item"
                    v-else-if="this.taskType.toLowerCase() === 'loan account'"
                  >
                    <label class="form__label"
                      >Select {{ taskType }} name</label
                    >
                    <multiselect
                      v-model="selectedCustomer"
                      id="linked"
                      label="loanName"
                      track-by="loanName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="sortedLoans"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="7"
                      :max-height="800"
                      :loading="loading"
                      :preselect-first="false"
                      @input="changeSelectedKey"
                      @change="changeSelectedKey"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.groupName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                  <div
                    class="form__item"
                    v-else-if="
                      this.taskType.toLowerCase() === 'group loan account'
                    "
                  >
                    <label class="form__label"
                      >Select {{ taskType }} name</label
                    >
                    <multiselect
                      v-model="selectedCustomer"
                      id="linked"
                      label="loanName"
                      track-by="loanName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="filterGroupLoan"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="7"
                      :max-height="800"
                      :loading="loading"
                      :preselect-first="false"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.loanName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                  <div
                    class="form__item"
                    v-else-if="
                      this.taskType.toLowerCase() === 'group deposit account'
                    "
                  >
                    <label class="form__label"
                      >Select {{ taskType }} name</label
                    >
                    <multiselect
                      v-model="selectedCustomer"
                      id="linked"
                      label="savingsAccountName"
                      track-by="savingsAccountName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="filterGroupDeposits"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="7"
                      :max-height="800"
                      :loading="loading"
                      :preselect-first="false"
                      @input="changeSelectedKey"
                      @change="changeSelectedKey"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.groupName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                  <div
                    class="form__item"
                    v-else-if="this.taskType.toLowerCase() === 'branch'"
                  >
                    <label class="form__label"
                      >Select {{ taskType }} name</label
                    >
                    <multiselect
                      v-model="selectedCustomer"
                      id="linked"
                      label="branchAssigned"
                      track-by="branchAssigned"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="this.customers"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="3"
                      :limit-text="limitText"
                      :max-height="800"
                      :show-no-results="false"
                      :hide-selected="true"
                      @input="changeSelectedKey"
                      @change="changeSelectedKey"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.branchName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                  <div
                    class="form__item"
                    v-else-if="this.taskType.toLowerCase() === 'centre'"
                  >
                    <label class="form__label"
                      >Select {{ taskType }} name</label
                    >
                    <multiselect
                      v-model="selectedCustomer"
                      id="linked"
                      label="centreName"
                      track-by="centreName"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="this.allCenters"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="3"
                      :limit-text="limitText"
                      :max-height="800"
                      :show-no-results="false"
                      :hide-selected="true"
                      @input="changeSelectedKey"
                      @change="changeSelectedKey"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.branchName }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Assign To</label>
                    <multiselect
                      v-model="assignedPerson"
                      id="ajax"
                      label="name"
                      track-by="name"
                      placeholder="Find Users"
                      open-direction="bottom"
                      :options="users"
                      :custom-label="name"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :options-limit="300"
                      :limit="3"
                      :limit-text="limitText"
                      :max-height="800"
                      :show-no-results="false"
                      :hide-selected="true"
                      @input="selectAssignedUser"
                    >
                      <template slot="tag" slot-scope="{ option, remove }">
                        <span class="custom__tag">
                          <span>{{ option.name }}</span>
                          <span class="custom__remove" @click="remove(option)"
                            >❌</span
                          >
                        </span>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label">Due Date</label>
                    <input
                      type="date"
                      class="input form__input form__input--lg"
                      placeholder=""
                      v-model="task.dueDate"
                      v-bind:class="{
                        'input-required': taskFormErr.dueDate,
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="form__item">
                <textarea
                  cols="5"
                  rows="5"
                  class="input form__input"
                  placeholder="Profile Note"
                  v-model="task.taskDescription"
                  v-bind:class="{
                    'input-required': taskFormErr.taskDescription,
                  }"
                  :style="
                    taskFormErr.taskDescription
                      ? 'border:1px solid red !important'
                      : ''
                  "
                ></textarea>
              </div>
              <div class="form__item">
                <div class="form__action">
                  <button
                    data-dismiss="#confirmModal"
                    class="button form__button form__button--lg save-changes"
                    v-if="loading"
                  >
                    <div class="spinner-border texxt-light" role="status"></div>
                  </button>
                  <button
                    v-else
                    class="button form__button form__button--lg w-30 save-changes"
                    type="button"
                    @click.prevent="createTask"
                  >
                    Save Task
                  </button>
                  <a
                    role="button"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="$emit('close')"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
import GeneralApiService from "@/core/services/general.service";
import LoanApiService from "@/core/services/api.loan.service";
import AccessApiService from "@/core/services/access.service";
import { mapState } from "vuex";
// import moment from "moment"

export default {
  name: "CreateTask",
  props: {
    show: Boolean,
    close: Function,
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      customers: [],
      groups: [],
      loading: false,
      customer: {
        pageIndex: 1,
        pageSize: 1000000,
        filters: [],
        customerStatus: "",
      },
      center: {
        pageIndex: 1,
        pageSize: 20,
      },
      selectedCustomer: null,
      assignedPerson: null,
      taskType: "",
      task: {
        assignedUserKey: "2150b9d09ecb483d8eff635c9b067d9b",
        createdbyUserKey: "2150b9d09ecb483d8eff635c9b067d9b",
        taskDescription: "",
        taskTitle: "",
        taskLinkKey: "",
        taskLinkType: "",
        dueDate: "",
      },
      taskFormErr: {
        assignedUserKey: false,
        createdByUserKey: false,
        taskDescription: false,
        taskTitle: false,
        taskLinkKey: false,
        taskLinkType: false,
        dueDate: false,
      },
      allTaskType: [
        { name: "Customer", value: "Customer" },
        { name: "Group", value: "Group" },
        // { name: "Group - Loan Account", value: "Group Loan Account" },
        { name: "Group - Deposit Account", value: "Group Deposit Account" },
        { name: "Customer - Loan Account", value: "Loan Account" },
        { name: "Customer - Deposit Account", value: "Deposit Account" },
        { name: "Centre", value: "Centre" },
        { name: "Branch", value: "Branch" },
      ],
      users: [],
      depositAccounts: [],
      loanAccounts: [],
      groupLoans: [],
      groupDeposits: [],
      allCenters: [],
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      userKey: "",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    sortedLoans() {
      const LoansArray = this.loanAccounts;
      return LoansArray.filter(
        (group) => group.account_holder_type.toLowerCase() === "client"
      ).sort((a, b) => a.loanName.localeCompare(b.loanName));
    },
    sortedDeposits() {
      const depositArrays = this.depositAccounts;
      return depositArrays
        .filter(
          (group) => group.accountHolderType.toLowerCase() === "customers"
        )
        .sort((a, b) =>
          a.savingsAccountName.localeCompare(b.savingsAccountName)
        );
    },
    filterGroupDeposits() {
      const depositArrays = this.depositAccounts;
      return depositArrays
        .filter((group) => group.accountHolderType.toLowerCase() === "groups")
        .sort((a, b) =>
          a.savingsAccountName.localeCompare(b.savingsAccountName)
        );
    },
    filterGroupLoan() {
      const loanArrays = this.loanAccounts;
      return loanArrays
        .filter((group) => group.account_holder_type.toLowerCase() !== "client")
        .sort((a, b) => a.loanName.localeCompare(b.loanName));
    },
  },
  methods: {
    limitText(count) {
      return `and ${count} other customers`;
    },
    resetTaskForm() {
      this.task = {
        assignedUserKey: "",
        createdbyUserKey: "",
        taskDescription: "",
        taskTitle: "",
        taskLinkKey: "",
        taskLinkType: "",
        dueDate: "",
      };
      this.selectedCustomer = null;
      this.assignedPerson = null;
      this.taskType = "";
    },
    changeSearchParams() {
      // if (this.taskType === "Customer") {
      //   this.getCustomers();
      // } else
      // if (this.taskType === "Group") {
      //   this.getGroups();
      // }
    },
    async getLoginUser() {
      await AccessApiService.post("ProleanUser/getuserdetails", {}).then(
        async (response) => {
          const data = response.data.data;
          await this.getLoanAccounts(data.userKey);
          await this.getDepositAccounts(data.userKey);
        }
      );
    },
    async getClient() {
      await GeneralApiService.post("Centre/Centres", this.center).then(
        (resp) => {
          this.allCenters = resp.data.data.items;
        }
      );
    },
    name({ name }) {
      return `${name}`;
    },
    async getCustomers() {
      await GeneralApiService.post("Customer/customers", this.customer).then(
        (resp) => {
          this.customers = resp.data.data.items;
        }
      );
    },
    customCustomer({
      customerFirstName,
      customerLastName,
      customerMiddleName,
    }) {
      return `${customerFirstName} ${customerMiddleName} ${customerLastName}`;
    },
    async getUsers() {
      const page = {
        pageNumber: 1,
        pageSize: 10000,
        status: true,
        filters: [],
      };
      await AccessApiService.post(
        "ProleanUser/GetUsersWithoutPermission",
        page
      ).then((response) => {
        this.users = response.data.data;
      });
    },
    async getDepositAccounts(key) {
      const page = {
        pageIndex: 1,
        pageSize: 1000000,
        filters: [],
        userKey: key,
      };
      await ApiService.post(
        "DepositAccount/GetFilteredDepositAccount",
        page
      ).then((resp) => {
        this.depositAccounts = resp.data.data;
      });
    },
    async getLoanAccounts(key) {
      const page = {
        pageIndex: 1,
        pageSize: 1000000,
        filters: [],
        userKey: key,
      };
      await LoanApiService.post(
        "LoanAccountFilter/GetFilteredLoanAccount",
        page
      ).then((resp) => {
        this.loanAccounts = resp.data.data;
      });
    },
    async getGroups() {
      const page = {
        pageIndex: 1,
        pageSize: 1000000,
        filters: [],
        filter: "",
      };
      await GeneralApiService.post("Group/Groups", page).then((resp) => {
        this.groups = resp.data.data.items;
      });
    },
    changeSelectedKey() {
      if (this.taskType === "Branch") {
        this.task.taskLinkKey = this.selectedCustomer.assignedBranchKey;
      } else if (
        this.taskType === "Group Deposit Account" ||
        this.taskType === "Deposit Account"
      ) {
        this.task.taskLinkKey = this.selectedCustomer.savingsAccountKey;
      } else {
        this.task.taskLinkKey =
          this.selectedCustomer.customerKey ||
          this.selectedCustomer.groupKey ||
          this.selectedCustomer.centreKey ||
          this.selectedCustomer.loanAccountKey;
      }

      this.task.taskLinkType =
        this.taskType === "Group Deposit Account"
          ? "Deposit Account"
          : this.taskType;
    },
    selectAssignedUser() {
      this.task.assignedUserKey = this.assignedPerson.userKey;
    },
    clearTaskValidation() {
      this.taskFormErr = {
        assignedUserKey: false,
        createdByUserKey: false,
        taskDescription: false,
        taskTitle: false,
        taskLinkKey: false,
        taskLinkType: false,
        dueDate: false,
      };
    },
    validateTaskForm() {
      this.clearTaskValidation();
      if (
        this.task.taskTitle &&
        this.task.dueDate &&
        this.task.taskDescription
      ) {
        return true;
      }

      if (!this.task.taskTitle) {
        this.taskFormErr.taskTitle = true;
      }
      if (!this.task.taskDescription) {
        this.taskFormErr.taskDescription = true;
      }
      if (!this.task.dueDate) {
        this.taskFormErr.dueDate = true;
      }
      return false;
    },
    createTask() {
      // const dueDate = moment(this.task.dueDate).format("DD-MMM-YYYY");
      // if (moment().isAfter(dueDate, "day")) {
      //   this.alertWarning("The task due date cannot be a past date");
      // } else {

      // }
      if (this.validateTaskForm()) {
        this.loading = true;
        ApiService.post("TaskManagement/createtask", this.task)
          .then((response) => {
            if (response.data.status !== false) {
              this.loading = false;
              this.$emit("task-created-successfully", response.data.message);
              this.$emit("close");
              this.resetTaskForm();
            } else {
              this.loading = false;
              this.$emit("error-creating-task", response.data.message);
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$emit("error-creating-task", error.response.data.message);
          });
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 3000);
    },
  },
  // async mounted() {
  //   // this.$nextTick(() => {
  //     this.getUsers();
  //     this.getLoginUser();
  //     this.getCustomers();
  //     this.getGroups();
  //     this.getClient();
  //   // });
  // },
  watch: {
    show(change) {
      if (change) {
        this.getUsers();
        this.getLoginUser();
        this.getCustomers();
        this.getGroups();
        this.getClient();
      }
    },
  },
};
</script>
